(function ($) {

  $.fn.linkTap = function () {

    let setTargetBlank = function (that) {
      let href = $(that).attr('href');

      if (href && href.indexOf('http') === 0 && href.length > 1) {
        $(that).attr('target', '_blank');
      }
    }

    $(this).on('click.blank', 'a', function () {
      setTargetBlank($(this));
    });
  };

}(jQuery));