(function ($) {

    $.fn.mdLinkToButton = function (e) {

        var elements = $(this);

        elements.each(function (element) {

            var title = $(this).attr('title');

            if (title.substr(0, 6) === 'button') {
                var title_array = title.split('|');
                var cls = $.trim(title_array[1]);
                if (cls) {
                    $(this).attr('class', cls);
                }
                var trg = $.trim(title_array[2]);
                if (trg) {
                    $(this).attr('data-target', trg);
                }
                var aml = $.trim(title_array[3]);
                if (aml) {
                    $(this).attr('data-mailto', aml);
                }
                $(this).attr('role', 'button');
                $(this).removeAttr('title');
            }
        });

    };

}(jQuery));