(function ($) {

    $.fn.scrollTopArrow = function () {


        var showArrow = function () {

            if ($(this).scrollTop() >= 300) {
                // If page is scrolled more than 50px
                $('a#scrolltop').fadeIn(200); // Fade in the arrow
            } else {
                $('a#scrolltop').fadeOut(200); // Else fade out the arrow
            }
        }

        $($(this)).on('scroll', function () {
            showArrow();
        });

    };


}(jQuery));