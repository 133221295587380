(function ($) {

    $.fn.mdImageSize = function () {

        var $element = this;

        var title = $element.attr('title');
        if (title) {
          var title_array = title.split('|');
          for (var i = 0; i < title_array.length; i++) {
            if (title_array[i].substr(0, 5) === 'width') {
              var width_array = title_array[i].split('=');
              var width = $.trim(width_array[1]);
              if (width) {
                $element.css('max-width', width);
              }
            } else {
              $element.addClass(title_array[i]);
            }
          }
          $element.removeAttr('title');
        }
    };

}(jQuery));