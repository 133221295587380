(function ($) {

  $(document).ready(function () {

    // ********** jQuery plugins ************ 
    
    // $('body').bobFormContact();


    // $('.jumbotron span[data-class^=hero]').each(function() {
    //   var element = $(this);
    //   var pclass = element.data('class');
    //   element.parent('p').addClass(pclass);
    //   console.log(element);
    //   element.remove();
    // });

    $(window).typeAnimation();

    $('body').bobFormVoucher();

    $('body').scrollToAnchors();

    // $('#bottom').bottomize();

    $('.glyphicons').glyphClone();

    $('.passage a[title^=button], .footer a[title^=button]').mdLinkToButton();

    $('.footer img').mdImageSize();

    $('body').bobButton();

    $('.body-content').linkTap();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('.bob-frame-show').bobframe();

  });

}(jQuery));
