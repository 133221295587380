(function ($) {

    $.fn.scrollToAnchors = function () {

        var scrollStart = function (e) {
            
            var file = $('body').attr('data-file');
            var href = $(e.currentTarget).attr('href');

            // href might not be set (e.g. in logofarm)
            if (href && href.indexOf('#') === 0 && href.length > 1) {
                if (href.indexOf('bob') === -1) {
                    e.preventDefault();
                    $('body').scrollTo(href, 300);

                }
            } else if (href && href.indexOf(file) === 0 && href.indexOf('#') > -1) {
                e.preventDefault();
                var link = '#' + href.split('#')[1];
                $('body').scrollTo(link, 300);
            }

        }

        $('a').on('click.scroll', function (e) {
            scrollStart(e);
        });

    };

}(jQuery));