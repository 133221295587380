(function ($) {

  $.fn.spinner = function () {

    var element = this;

    function setupSpinner() {
      // clone big spinner into total-cover
      $('body > .spinner').clone().appendTo('.total-cover');
    }

    function showSpinner() {
      // set up spinner
      if (!$('.total-cover .spinner').length) {
        setupSpinner();
      }
      // submit action
      $('.total-cover, .foggy-cover').show();
      $('.total-cover .spinner').stop(true, true).fadeIn(100);
    }

    function hideSpinner() {
      $('.total-cover .spinner').stop(true, true).fadeOut(100);
      $('.total-cover, .foggy-cover').css('display','none');
    }

    function toggleSpinner() {
      if ($('.spinner').is(":visible")) {
        hideSpinner();
      } else {
        showSpinner();
      }
    }

    // use as method command
    if (arguments.length > 0) {
      // method: show
      if (arguments[0] === 'show') {
        showSpinner();
      }

      // method: hide
      if (arguments[0] === 'hide') {
        hideSpinner();
      }

      // method: show
      if (arguments[0] === 'toggle') {
        toggleSpinner();
      }

    }

  };

}(jQuery));
